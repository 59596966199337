import { HeartIcon, StarIcon, SparklesIcon } from "@heroicons/react/24/solid";
import { ProductAwardType } from "prisma-types";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
dayjs.extend(isBetween);

export const AWARD_COLORS = {
  [ProductAwardType.MostLoved]: "emerald",
  [ProductAwardType.SupportStar]: "violet",
  [ProductAwardType.BestValue]: "teal",
} as const;

export const PRODUCT_CARD_AWARD_CONFIG = {
  [ProductAwardType.MostLoved]: {
    bgColor: `bg-${AWARD_COLORS[ProductAwardType.MostLoved]}-50/50`,
    borderColor: `border border-${AWARD_COLORS[ProductAwardType.MostLoved]}-50`,
    image: "/assets/2025-most-loved.png",
    toolkitUrl:
      "https://drive.google.com/drive/folders/1Lq13YQ8wmb3rkrR3y7YTzfoEqdyOqZlc?usp=sharing",
  },
  [ProductAwardType.SupportStar]: {
    bgColor: `bg-${AWARD_COLORS[ProductAwardType.SupportStar]}-50/50`,
    borderColor: `border border-${
      AWARD_COLORS[ProductAwardType.SupportStar]
    }-50`,
    image: "/assets/2025-support-star.png",
    toolkitUrl:
      "https://drive.google.com/drive/folders/1IZZADbA89upOVhrFB8UreiP-ClMtt5Mx?usp=sharing",
  },
  [ProductAwardType.BestValue]: {
    bgColor: `bg-${AWARD_COLORS[ProductAwardType.BestValue]}-50/50`,
    borderColor: `border border-${AWARD_COLORS[ProductAwardType.BestValue]}-50`,
    image: "/assets/2025-best-value.png",
    toolkitUrl:
      "https://drive.google.com/drive/folders/1uhfG8Igihy2bkTZzpBSnXbLTHYZwNhLN?usp=sharing",
  },
};

export const AWARD_ICONS = {
  [ProductAwardType.MostLoved]: HeartIcon,
  [ProductAwardType.SupportStar]: StarIcon,
  [ProductAwardType.BestValue]: SparklesIcon,
};

export const PRODUCT_AWARD_TYPE_CARDS_INFO = [
  {
    title: "Most Reviews",
    asset: PRODUCT_CARD_AWARD_CONFIG[ProductAwardType.MostLoved].image,
    description: "Most reviews + highest average star rating of 4.0 or higher",
    color: AWARD_COLORS[ProductAwardType.MostLoved],
  },
  {
    title: "Customer Support",
    asset: PRODUCT_CARD_AWARD_CONFIG[ProductAwardType.SupportStar].image,
    description:
      "Highest average star rating in “Customer Support” of 4.0 or higher",
    color: AWARD_COLORS[ProductAwardType.SupportStar],
  },
  {
    title: "Value",
    asset: PRODUCT_CARD_AWARD_CONFIG[ProductAwardType.BestValue].image,
    description: "Highest average star rating in “Value” of 4.0 or higher",
    color: AWARD_COLORS[ProductAwardType.BestValue],
  },
];

export function isWinnerPeriod() {
  const currentYear = dayjs().year();
  const startDate = dayjs(`${currentYear}-04-22`);
  const endDate = dayjs(`${currentYear}-05-22`);
  return dayjs().isBetween(startDate, endDate, "day", "[]");
}
